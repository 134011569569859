import { listOutline, listSharp } from 'ionicons/icons';
import { MenuEntry } from './MenuEntry';

export const entries: MenuEntry[] = [
  {
    title: 'Événements',
    url: '/events/',
    iosIcon: listOutline,
    mdIcon: listSharp,
  },
];
